export const reviews = "reviews";
export const reviewsWbJobLoadNew = "reviews:wb_load_new";
export const reviewsWbJobLoadOld = "reviews:wb_load_old";
export const reviewsWbJobSend = "reviews:wb_send";
export const reviewsWbJobApprove = "reviews:wb_approve";
export const reviewsWbJobReset = "reviews:wb_reset";
export const reviewsOzonJobLoadNew = "reviews:ozon_load_new";
export const reviewsOzonJobLoadOld = "reviews:ozon_load_old";
export const reviewsOzonJobSend = "reviews:ozon_send";
export const reviewsOzonJobApprove = "reviews:ozon_approve";
export const reviewsOzonJobReset = "reviews:ozon_reset";
